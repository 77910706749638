import React from 'react';
import style from './styles.module.css';

function QuemSomos() {
    return (
        <div className={style.quemsomos_container}>
            <h2>Quem Somos</h2>
            <p>
                No Grupo EcoPerfil, unimos tecnologia, sustentabilidade e inovação 
                para criar um futuro melhor. Nossa equipa multidisciplinar combina 
                expertise em engenharia, ecologia e saúde para desenvolver soluções 
                que respeitam o meio ambiente e melhoram a qualidade de vida das comunidades.
            </p>
        </div>
    );
}

export default QuemSomos; 
import React from 'react';
import style from './styles.module.css';

interface GalleryImage {
    src: string;
    alt: string;
    category: string;
}

const galleryImages: GalleryImage[] = [
    {
        src: "/images/gallery/eng-1.jpg",
        alt: "Projeto de Engenharia 1",
        category: "engenharia"
    },
    {
        src: "/images/gallery/med-1.jpg",
        alt: "EcoMed Facility",
        category: "medicina"
    },
    {
        src: "/images/gallery/net-1.jpg",
        alt: "Instalação EcoNet",
        category: "tecnologia"
    },
    {
        src: "https://via.placeholder.com/200", // Placeholder image
        alt: "Placeholder Image",
        category: "placeholder"
    },
];

function Galeria() {
    return (
        <div className={style.gallery_container}>
            <div className={style.section_header}>
                <h2>Galeria</h2>
                <p>Conheça nossos projetos e realizações</p>
            </div>
            
            <div className={style.gallery_grid}>
                {galleryImages.map((image, index) => (
                    <div key={index} className={style.gallery_item}>
                        <img src={image.src} alt={image.alt} />
                        <div className={style.overlay}>
                            <span>{image.alt}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Galeria; 
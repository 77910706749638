import React from 'react';
import style from './styles.module.css';
import Cards from '../Card';

function Servicos() {
    return (
        <div className={style.servicos_container}>
            <div className={style.section_header}>
                <h2>Nossos Serviços</h2>
                <p>Soluções integradas para um futuro sustentável</p>
            </div>
            <Cards />
        </div>
    );
}

export default Servicos; 
import { BrowserRouter as Router } from 'react-router-dom'
import Navbar from './Components/Navbar/Navbar'
import Hero from './Components/Hero/Hero'
import QuemSomos from './Components/QuemSomos/quemsomos'
import Servicos from './Components/Servicos'
import Projectos from './Components/Projectos/projectos'
import Galeria from './Components/Galeria'
import Contacto from './Components/Contacto'
import Footer from './Components/Footer'

function App() {
  return (
    <Router>
      <div className="app">
        <main>
        <Navbar />
          <section id="home">
            <Hero />
          </section>
          
          <section id="sobre-nos">
            <QuemSomos />
          </section>
          
          <section id="servicos">
            <Servicos />
          </section>
          
          <section id="projectos">
            <Projectos />
          </section>
          
          <section id="galeria">
            <Galeria />
          </section>
          
          <section id="contacto">
            <Contacto />
          </section>
        </main>
        <Footer />
      </div>
    </Router>
  )
}

export default App

import React from 'react'
import style from './styles.module.css'

interface Project {
    title: string;
    description: string;
    impact: string;
    image: string;
}

const featuredProjects: Project[] = [
    {
        title: "Projeto Solar Sustentável",
        description: "Implementação de energia solar em comunidades rurais",
        impact: "Redução de 50% no consumo de energia não-renovável",
        image: "/images/projeto-solar.jpg"
    },
    {
        title: "Telemedicina EcoMed",
        description: "Plataforma de atendimento médico remoto",
        impact: "Atendimento a mais de 1000 pacientes por mês",
        image: "/images/telemedicina.jpg"
    },
    {
        title: "Rede Rural EcoNet",
        description: "Conectividade para áreas remotas",
        impact: "Conexão de 15 comunidades rurais",
        image: "/images/rede-rural.jpg"
    }
];

const Projects = () => {
    return (
        <div className={style.projects_container}>
            <div className={style.section_header}>
                <h2>Projectos em Destaque</h2>
            </div>

            <div className={style.projects_grid}>
                {featuredProjects.map((project, index) => (
                    <div key={index} className={style.project_card}>
                        <img src={project.image} alt={project.title} />
                        <h3>{project.title}</h3>
                        <p>{project.description}</p>
                        <div className={style.impact}>
                            <strong>Impacto:</strong> {project.impact}
                        </div>
                        <a href="#" className={style.learn_more}>Saiba mais</a>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Projects
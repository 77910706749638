import React from 'react';
import style from './styles.module.css'
import CardsComponents from './component/cards';
import './styles.css';

interface ServiceCard {
  title: string;
  name: string;
  description: string;
  features: string[];
}

const serviceCards: ServiceCard[] = [
  {
    title: "Nossos Serviços",
    name: "Eco-Perfil Engenharia",
    description: "Soluções de engenharia sustentável para construção e infraestrutura",
    features: [
      "Consultoria em engenharia sustentável",
      "Projectos de construção verde",
      "Gestão ambiental",
      "Certificação energética",
      "Estudos de impacto ambiental"
    ]
  },
  {
    title: "Nossos Serviços",
    name: "EcoMed",
    description: "Serviços médicos com consciência ambiental",
    features: [
      "Serviços médicos especializados",
      "Telemedicina sustentável",
      "Gestão de resíduos hospitalares",
      "Consultoria em saúde ambiental"
    ]
  },
  {
    title: "Nossos Serviços",
    name: "EcoNet",
    description: "Conectividade de alta velocidade com baixo impacto ambiental",
    features: [
      "Internet de alta velocidade",
      "Infraestrutura de rede eco-friendly",
      "Soluções de conectividade rural",
      "Suporte técnico 24/7"
    ]
  }
];

function Cards() {
  return (
    <div className="cards-container">
      {serviceCards.map((card, index) => (
        <div key={index} className="card">
          <h3>{card.title}</h3>
          <h2>{card.name}</h2>
          <p>{card.description}</p>
        </div>
      ))}
    </div>
  );
}

export default Cards;

import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import style from './Hero.module.css'
import img1 from '../../assets/slide1.jpg';
import img2 from '../../assets/slide2.jpg';
import img3 from '../../assets/slide3.jpg';

function Hero() {
  return (
    <div className={style.hero_section}>
      <Carousel>
        <Carousel.Item interval={1000}>
          <img src={img1} className={style.img} alt="Eco-Perfil Engenharia"/>
          <Carousel.Caption className={style.panel}>
            <h3 className={style.hero_text}>Eco-Perfil Engenharia</h3>
            <p className={style.hero_text}>Soluções de engenharia sustentável para construção e infraestrutura</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <img src={img2} className={style.img} alt="EcoMed"/>
          <Carousel.Caption className={style.panel}>
            <h3 className={style.hero_text}>EcoMed</h3>
            <p className={style.hero_text}>Serviços médicos com consciência ambiental</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={img3} className={style.img} alt="Eco-NET"/>
          <Carousel.Caption className={style.panel}>
            <h3 className={style.hero_text}>Eco-NET</h3>
            <p className={style.hero_text}>Conectividade de alta velocidade com baixo impacto ambiental</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default Hero
import React from 'react';
import style from './styles.module.css';

function Footer() {
    return (
        <footer className={style.footer}>
            <div className={style.footer_content}>
                <div className={style.footer_section}>
                    <h4>Links Rápidos</h4>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#sobre-nos">Sobre Nós</a></li>
                        <li><a href="#servicos">Serviços</a></li>
                        <li><a href="#projectos">Projectos</a></li>
                    </ul>
                </div>

                <div className={style.footer_section}>
                    <h4>Redes Sociais</h4>
                    <ul className={style.social_links}>
                        <li><a href="#">Facebook</a></li>
                        <li><a href="#">LinkedIn</a></li>
                        <li><a href="#">Instagram</a></li>
                        <li><a href="#">Twitter</a></li>
                    </ul>
                </div>

                <div className={style.footer_section}>
                    <h4>Legal</h4>
                    <ul>
                        <li><a href="#">Política de Privacidade</a></li>
                        <li><a href="#">Termos de Uso</a></li>
                    </ul>
                </div>
            </div>

            <div className={style.footer_bottom}>
                <p>&copy; 2024 Eco-Perfil Group. Todos os direitos reservados.</p>
            </div>
        </footer>
    );
}

export default Footer; 
import React, { useState } from "react";
import style from "./Navbar.module.css";
import logo from "../../assets/princi-logo.png";
import menu_icon from "../../assets/menu.png";

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        document.body.style.overflow = isMenuOpen ? 'unset' : 'hidden';
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
        document.body.style.overflow = 'unset';
    };

    return (
        <>
            <nav className={style.navbar}>
                <div className={style.logo}>
                    <a href="/" className={style.logo_link}>
                        <img src={logo} className={style.logo} alt="ecoperfil" />
                    </a>
                </div>

                <img
                    src={menu_icon}
                    className={style.menu_icon}
                    alt="Menu"
                    onClick={toggleMenu}
                />

                <ul className={`${style.menu_list} ${isMenuOpen ? style.menu_open : ''}`}>
                    <li><a href="#home">Home</a></li>
                    <li><a href="#sobre-nos">Sobre Nós</a></li>
                    <li><a href="#servicos">Serviços</a></li>
                    <li><a href="#projectos">Projectos</a></li>
                    <li><a href="#galeria">Galeria</a></li>
                    {/* <li><a href="#contacto">Contacto</a></li> */}
                </ul>

                <button className={style.btn_contacto}>Contacte-Nos</button>
            </nav>
            {isMenuOpen && <div className={style.overlay} onClick={closeMenu} />}
        </>
    );
}

export default Navbar;

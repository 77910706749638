import React, { useState } from 'react';
import style from './styles.module.css';

function Contacto() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Handle form submission
        console.log(formData);
    };

    return (
        <div className={style.contact_container}>
            <div className={style.section_header}>
                <h2>Contacto</h2>
                <p>Entre em contacto connosco</p>
            </div>

            <div className={style.contact_content}>
                <div className={style.contact_info}>
                    <div className={style.info_item}>
                        <h3>Endereço</h3>
                        <p>Rua Principal, 123</p>
                        <p>Luanda, Angola</p>
                    </div>
                    <div className={style.info_item}>
                        <h3>Contacto</h3>
                        <p>Tel: +123 456 789</p>
                        <p>Email: info@ecoperfil.online</p>
                    </div>
                    <div className={style.info_item}>
                        <h3>Horário</h3>
                        <p>Segunda a Sexta: 9h - 18h</p>
                        <p>Sábado: 9h - 13h</p>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className={style.contact_form}>
                    <div className={style.form_group}>
                        <input
                            type="text"
                            placeholder="Nome"
                            onChange={(e) => setFormData({...formData, name: e.target.value})}
                            required
                        />
                    </div>
                    <div className={style.form_group}>
                        <input
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setFormData({...formData, email: e.target.value})}
                            required
                        />
                    </div>
                    <div className={style.form_group}>
                        <input
                            type="text"
                            placeholder="Assunto"
                            onChange={(e) => setFormData({...formData, subject: e.target.value})}
                            required
                        />
                    </div>
                    <div className={style.form_group}>
                        <textarea
                            placeholder="Mensagem"
                            onChange={(e) => setFormData({...formData, message: e.target.value})}
                            required
                        />
                    </div>
                    <button type="submit" className={style.submit_btn}>Enviar Mensagem</button>
                </form>
            </div>
        </div>
    );
}

export default Contacto; 